/**
 *  @file       WorkerPanel.jsx
 *  @author     Eddie Roosenmaallen <eddie@kingsds.network>
 *  @author     Kirill Kirnichansky <kirill@kingsds.network>
 *
 *  @date       June 2022
 *
 *  This panel presents a Worker interface which looks like the old Portal
 *  worker, but tied into the global portalWorker API
 */

import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDCPWorker } from '@/hooks/useDCPWorker';
import { debugging } from '@/lib/debugging';
import { AccountsContext, AccountSelect, DCC } from '@/features/accounts';
import { Button } from '@/components/Elements';
import { WorkerDebugDialog } from '../WorkerDebugDialog'

import './WorkerPanel.css';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';

/**
 * Render a full-window browser worker UX
 *
 * @param  {object}      _props Expected properties from parent
 * @return {JSX.Element}        The rendered component.
 */
export function WorkerPanel(_props)
{
  const { t } = useTranslation();
  const accountsRef = useRef();
  const cpuCoresInputRef = useRef();
  const accounts = useContext(AccountsContext);

  if (!accounts?.[0])
    throw new Error('No account to deposit credits into', );

  const {
    worker,
    workerState,
    workerStatistics,
    toggleWorker,
  } = useDCPWorker({
    workerOptions: {
      paymentAddress: accounts[0].address,
      dbKey: accounts[0].dbKey,
    },
  });

  // computeTime is in seconds, formatTimeInterval expects ms.
  const computeTime = formatTimeInterval(workerStatistics.computeTime * 1000);

  let status = t('status_ready');

  if (workerState.working && workerState.workingSandboxes <= 0)
    status = t('status_waiting');
  else if (workerState.working)
    status = t('status_working');

  if (workerState.fetching)
    status = t('status_fetching');

  if (workerState.submitting)
    status = t('status_submitting');

  if (workerState.serviceError)
    status = t('status_error');

  const updatePaymentAddress = () => {
    const selectedAccount = accounts.find(account => account.dbKey === Number(accountsRef.current.value));
    worker.workerOptions.paymentAddress = String(selectedAccount.address);
    worker.workerOptions.dbKey = String(selectedAccount.dbKey);
  };

  const workButtonLabel = (() => {
    if (workerState.willWork === true)
      return t('starting');
    else if (workerState.willWork === false)
      return t('stopping');

    return workerState.working ? t('stop') : t('start');
  })();

  const setCoresInputBorder = () => {
    let borderColor = false;    // default

    if (cpuCoresInputRef.current.value > (navigator.hardwareConcurrency * 2))
      borderColor = '#b83b33';  // 2 times over hardwareConcurrency -> red
    else if (cpuCoresInputRef.current.value > navigator.hardwareConcurrency)
      borderColor = '#eb9f22';  // over hardwareConcurrency -> orange

    cpuCoresInputRef.current.style.border = borderColor ? `2.5px solid ${borderColor}` : '';

    // display note if over hardwareConcurrency
    const workerOptionsNote = document.getElementById('workerOptionNote');
    if (!borderColor)
      workerOptionsNote.style.display = 'none';
    else
    {
      workerOptionsNote.style.display = 'block';
      workerOptionsNote.style.color = borderColor;
    }
  }

  let typingTimeout;
  const configureCpuCoresOption = () => {
    // force max 3 decimal places
    if (cpuCoresInputRef.current.value.split('.')[1] && cpuCoresInputRef.current.value.split('.')[1].length > 1)
      cpuCoresInputRef.current.value = parseFloat(cpuCoresInputRef.current.value).toFixed(1);
    // force 3 digits before decimal
    if (cpuCoresInputRef.current.value.split('.')[0].length > 3)
    {
      const split = cpuCoresInputRef.current.value.split('.');
      let val = split[0].slice(0, 3);
      if (split[1]) val += '.' + split[1];
      cpuCoresInputRef.current.value = val;
    }

    setCoresInputBorder();

    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      if (worker)
      {
        // if val <= 0, reset input back to workerOptions.cores.cpu
        if (parseFloat(cpuCoresInputRef.current.value) >= 0)
          worker.workerOptions.cores.cpu = parseFloat(cpuCoresInputRef.current.value);
        else
        {
          cpuCoresInputRef.current.value = worker.workerOptions.cores.cpu;
          setCoresInputBorder();
        }
      }
    }, 700);
  }

  useEffect(() => {

    async function fetchBrandCg() 
    {
      const response = await fetch('/assets/dcp-config.json');
      const brandConfig = await response.json().catch(() => ({}));
      if (brandConfig.worker?.computeGroups)
        worker.workerOptions.computeGroups = brandConfig.worker.computeGroups;
      if (typeof brandConfig.worker?.leavePublicGroup === 'boolean')
        worker.workerOptions.leavePublicGroup = brandConfig.worker.leavePublicGroup;
      if (brandConfig.worker?.allowOrigins)
      {
        const originSets = [
          'fetchArguments',
          'fetchData',
          'fetchWorkFunctions',
          'sendResults'
        ];
        for (const origin of brandConfig.worker.allowOrigins.any || [])
          worker.originManager.add(origin, null, null);
        for (const purpose of originSets)
          for (const origin of brandConfig.worker.allowOrigins[purpose] || [])
            worker.originManager.add(origin, purpose, null);
      }
    }

    if (worker)
    {
      cpuCoresInputRef.current.value = worker.options.cores.cpu;
      fetchBrandCg();
    }
    setCoresInputBorder();

  }, [worker]);

  return (
    <div className="worker-page">
      <h1>{t('dcp_web_worker')}</h1>

      <div className="worker-page-content">
        {/* Worker Config Options */}
        <div className="worker-section">
          
          {/* Run Worker */}
          <div id="run-worker" className="worker-config-option">
            <label htmlFor="run-worker-btn">{t('run_worker')}</label>
            <Button
              id="run-worker-btn"
              onClick={() => toggleWorker()}
              primary=""
              text={workButtonLabel}
            />
          </div>

          {/* Deposit Account */}
          <div className="worker-config-option">
            <label htmlFor="deposit-account">{t('deposit_account')}</label>
            <AccountSelect
              id="deposit-account"
              ref={accountsRef}
              accounts={accounts}
              selected={worker?.workerOptions.dbKey ?? ''}
              onChange={updatePaymentAddress}
            />
          </div>

          {/* Max Number of Sandboxes */}
          <div className="worker-config-option">
            <label htmlFor="cpu-cores-input">{t('cpu_cores')}</label>
            <input
              id="cpu-cores-input"
              ref={cpuCoresInputRef}
              onChange={configureCpuCoresOption}
              type="number"
              min="0"
              max="999"
              step="1"
            />
            
            {/* Max Sandbox Error Message */}
            <p id="workerOptionNote">
              <strong>{t('note')}</strong> {t('validation:worker_page.max_sandbox_limit')}.
            </p>
          </div>
        </div>

        <div className="worker-section">
          {/* Credits Earned */}
          <div className="worker-stats">
            <label htmlFor="slices">{t('slices_completed')}</label>
            <div>
              <output id="slices" className="dash-metric font-monospace">{workerStatistics.slices.toLocaleString()}</output>
            </div>
          </div>

          {/* Slices Computed */}
          <div className="worker-stats">
            <label htmlFor="credits-earned">{t('credits_earned')}</label>
            <div>
              <DCC id="credits-earned" className="dash-metric font-monospace" balance={workerStatistics.credits} />
            </div>
          </div>


          {/* Computing Time */}
          <div className="worker-stats">
            <label>{t('computing_time')}</label>
            
            {/* Time Stats */}
            <div>
              <div className='time-stats'>
                <div>
                  <label htmlFor="time-in-days" className="dash-metric font-monospace">{computeTime.days}</label>
                  <output id="time-in-days">{t('days')}</output>
                </div>
                <div>
                  <label htmlFor="time-in-hours" className="dash-metric font-monospace">{computeTime.hours}</label>
                  <output id="time-in-hours">{t('hours')}</output>
                </div>
                <div>
                  <label htmlFor="time-in-minutes" className="dash-metric font-monospace">{computeTime.minutes}</label>
                  <output id="time-in-minutes">{t('minutes')}</output>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="workerCurrentStatus card">
          <div className="worker-status-title currentStatusTitle">
            <label htmlFor="worker-status">
              {t('status')}: {status}
              <span id="worker-status" className={`${(status === t('status_ready') || status === t('status_error')) ? '' : 'ellipses'}`}/>
            </label>
          </div>
          {worker?.workingSandboxes.length > 0 ? (
            <div className="worker-sandboxes-container">
              {worker.workingSandboxes.map(({ sandboxHandle }) => (
                <SandboxProgress key={sandboxHandle.id} sandbox={sandboxHandle} />
              ))}
            </div>
          ) : (
            <div className="zero-sandbox-msgs">
              {worker?.working ? (
                <output>{t('waiting_for_work')}&#8230;</output>
              ) : (
                <output>{t('worker_not_started')}&#8230;</output>
              )}
            </div>
          )}
        </div>
      </div>
      <WorkerDebugDialog workerOptions={worker?.workerOptions}/>
    </div>
  );
}

/**
 * Progress bar for a single Sandbox which self-manages event listeners and
 * progress updates
 *
 * @param {object}  props
 * @param {Sandbox} props.sandbox A DCP Worker Sandbox
 */
function SandboxProgress(props)
{
  const { t } = useTranslation();
  const { sandbox } = props;
  const { id } = sandbox;

  const [ name, setName ] = useState(sandbox.public.name);
  const [ description, setDescription ] = useState(sandbox.public.description);

  const [ progress, setProgress ] = useState(0);
  const [ indeterminate, setIndeterminate ] = useState(false);

  const onProgress = ev => {
    setProgress(ev);
    setIndeterminate(ev === undefined);
  }

  const onStart = ev => {
    debugging('worker') && console.debug(`283: sandbox${ev.sandbox.id}: start event:`, ev);
    setName(ev.name);
    setDescription(ev.description);
    // setComputeGroups(ev.job.computeGroups || ''); //NYI

    setProgress(0);
    setIndeterminate(false);
  }

  useEffect(() => {
    props.sandbox?.on('progress', onProgress);
    props.sandbox?.on('start', onStart);

    return () => {
      props.sandbox?.off('progress', onProgress);
      props.sandbox?.off('start', onStart);
    }
  }, [props.sandbox]);

  // render():
  const label = name || t('waiting_for_assignment');
  const title = description || t('discreetly_making_the_world_smarter');

  const progressClassName = `worker-sandbox-progress-container${indeterminate ? ' indeterminate' : ''}`;
  const progressStyle = { width: `${progress}%` };

  return (
    <Tooltip
      side="top"
      className="worker-sandbox-tooltip"
      content={
        <div className="worker-sandbox-tooltip-content">
          <span>{label}</span>
          <br />
          <span>{title}</span>
        </div>
      }
    >
      <div id={`progressContainer-${id}`} className={progressClassName}>
        <div
          id={`progressBar-${id}`}
          className="worker-sandbox-progress-bar progress-bar-striped progress-bar-animated"
          style={progressStyle}
        />
        <div id={`progressLabel-${id}`} className="worker-sandbox-progress-label">
          {label}
        </div>
      </div>
    </Tooltip>
  );
}

/**
 * Format a time interval expressed in milliseconds into an object containing properites: days, hours, minutes
 * and seconds of the interval. The properties represent a portion of the time interval, not the full interval
 * representaion in the respective unit. The time interval is a combination of all values.
 *
 * @param ms The number of milliseconds in the time interval
 *
 * @returns  the time interval as an object
 */
function formatTimeInterval(ms)
{
  let timeInterval = {
    days: '0',
    hours: '0',
    minutes: '0',
    seconds: '0',
  };

  ms = Math.floor(ms);

  if (ms >= 86400000)
  {
    timeInterval.days = Math.floor(ms / 86400000).toFixed(0);
    ms = ms % 86400000;
  }

  timeInterval.hours = (Math.floor(ms / 3600000) % 24).toString();
  timeInterval.minutes = (Math.floor(ms /   60000) % 60).toString();
  timeInterval.seconds = (Math.floor(ms /    1000) % 60).toFixed(1);

  return timeInterval
}
