/**
 *  @file       widgets/accounts-list/account/account.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *  @author     Kevin Yu <kevin@distributive.network>
 *
 *  @date       April 2022
 *
 *              A component for displaying an account's listing as part of the accounts list
 *              References an element with an id of the account's address which is the more
 *              pane for that component
 *              Props:
 *                - account: the keystore object for the account
 *                  - account.balance: the keystore object should have either a balance value or something falsey if locked
 */

import { useState, useEffect, useRef } from 'react';

import { CopyToClipboard } from '@/components/Elements';
import { AccountDropdown } from './AccountDropdown';
import { DCC } from '../../DCC';
import {
  ChangeAccountPasswordModal,
  DeleteAccountModal,
  DownloadKeystoreModal,
  RenameAccountModal,
} from '../../Modals';

import './Account.css';
import { useNavigate } from 'react-router-dom';
import { formatBankAccountAddress } from '@/features/accounts/utils/format';
import { t } from 'i18next';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';


/**
 * Renders a component for displaying an account's listing as part of the accounts list.
 *
 * @param   {object}      props         Component properties.
 * @param   {object}      props.account The account to display information for.
 * @returns {JSX.Element}               The table row component.
 */
export function Account({ account })
{
  const [isUnlocked, setIsUnlocked] = useState(account.isUnlocked());
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const copyRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    function setAccountLocked()
    {
      setIsUnlocked(false);
    }

    function setAccountUnlocked()

    {
      setIsUnlocked(true);
    }

    account.on('lock', setAccountLocked);
    account.on('unlock', setAccountUnlocked);

    return () => {
      account.off('lock', setAccountLocked);
      account.off('unlock', setAccountUnlocked);
    };
  }, [account]);

  const handleNavigateToTransactions = () => {

    if (account.isLocked())
      return;
    navigate(`/accounts/history?account=${account.label}`);
  };

  return (
    <tr
      id={account.address}
      className={`accountEntry ${isUnlocked ? '' : ' locked'}`}
    >
      {/* Account */}
      <td className="account-label">
        <Tooltip
          side="bottom"
          content={account.label}
          asChild
        >
          <button onClick={handleNavigateToTransactions} data-reset>{account.label}</button>
        </Tooltip>
      </td>

      {/* Account Number */}
      <td className="accountNumber">
        <div
          onClick={(event) => copyRef.current.handleClickToCopy(event)}
          role="button"
        >
          <button 
            type="button"
            className="text" 
            title={account.address} 
            onClick={(event) => copyRef.current.handleClickToCopy(event)}
            data-reset
          >
            {formatBankAccountAddress(account.address)}
          </button>
          <CopyToClipboard
            copyText={String(account.address).toLowerCase()}
            ref={copyRef}
            className="xs-hidden"
            toastMessage={t('copied_account_number')}
          />
        </div>
      </td>

      {/* Credits */}
      <td className="balance">
        <DCC balance={account.balance} />
      </td>

      {/* Menu Icon/Locked Icon */}
      <td>
        <AccountDropdown
          className="dropdownMenu"
          account={account}
          openRenameModal={() => setIsRenameModalOpen(true)}
          openChangeAccountPasswordModal={() => setIsChangePasswordModalOpen(true)}
          openDeleteModal={() => setIsDeleteModalOpen(true)}
          openDownloadModal={() => setIsDownloadModalOpen(true)}
          isUnlocked={isUnlocked}
          handleNavigateToTransactions={handleNavigateToTransactions}
        />
      </td>
      <RenameAccountModal
        isOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
        account={account}
      />
      <ChangeAccountPasswordModal
        isOpen={isChangePasswordModalOpen}
        closeModal={() => setIsChangePasswordModalOpen(false)}
        account={account}
      />
      <DeleteAccountModal
        isOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        account={account}
      />
      <DownloadKeystoreModal
        isOpen={isDownloadModalOpen}
        closeModal={() => setIsDownloadModalOpen(false)}
        account={account}
      />
    </tr>
  );
}
